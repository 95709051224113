import React, { memo } from "react";
import MenuTopBarContent from "./MenuTopBarContent";
import { AppBar } from "@mui/material";

function MenuTopBar() {

  return (
    <AppBar
      position="fixed"
      sx={{
        height: "4rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <MenuTopBarContent />
    </AppBar>
  );
}
export default memo(MenuTopBar);
