import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const logoutText = "wird ausgeloggt - mögen die Projekte gelingen!";

export default function CircularProgressWithoutLabel(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            Bye!
          </Typography>
        </Box>
      </div>
      <div>
        <Typography variant="caption" component="div" color="text.secondary">
          {logoutText}
        </Typography>
      </div>
    </div>
  );
}
