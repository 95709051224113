import { memo } from "react";
import LoginBox from "../../../4_generalHelpers/1_Boxes/LoginBox";
import SignUpCard from "./SignUpFormComponents/SignUpCard";
import LeftInformation from "../LeftInformation/LeftInformation";

function SignUpForm({ flipCard, seeLogin}) {
  return (
    <LoginBox>
      <LeftInformation flipCard={flipCard} loginPage={seeLogin} />
      <SignUpCard />
    </LoginBox>
  );
}

export default memo(SignUpForm);
