import axios from "axios";
import { SERVER_URL } from "../2_deployment/constants";
import authHeader from "./auth-header";
// Function to convert a file to base64
function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
}
class ImageService {
  upload = async (id, imageData) => {
    // console.log(imageData)
    const base64Image = await fileToBase64(imageData); // Convert the image file to base64
    try {
      const response = await axios.post(
        SERVER_URL + "userupdate/avatar/" + id,
        { image: base64Image }, // Ensure this is a JSON object
        {
          headers: {
            ...authHeader(),
            "Content-Type": "application/json"
          }, // Ensure the Content-Type is correct
        }
      );

      return response.data; // Assuming the response contains the updated user data with the new avatar
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('General error:', error.message);
      }
      throw error;
    }
  };

  getCurrentAvatar() {

    return axios
      .get(SERVER_URL + "update/currentavatar", { headers: authHeader() })
  }
}

const imageServiceInstance = new ImageService();
export default imageServiceInstance;
