import { memo } from "react";
import { useTheme } from "@mui/styles";
import ProjectLinkBox from "./ProjectLinkBox";
import UserQuestion from "./UserQuestion";
import MyButton from "../../../4_generalHelpers/2_Buttons/MyButton";

function LeftInformation({ flipCard, loginPage }) {
  const theme = useTheme();
  const userQuestionText = loginPage
    ? "Noch nicht registriert?" : "Bereits registriert?";
  const buttonText = loginPage ? "Sign up!" : "Log in!";

  return (
    <div
      style={{
        flex: 0.6,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.light,
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
        gap: "1em",  // Adds space between items
        overflow: "hidden"

      }}
    >
      <UserQuestion text={userQuestionText} />
      <MyButton job={flipCard} text={buttonText} />
      <ProjectLinkBox />
    </div>
  );
}

export default memo(LeftInformation);
