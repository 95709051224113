async function getErrorMessageFromResponse(key, response) {
  let errorMsg = "";

  if (key === "login") {
    if (response.request?.status === 409) {
      errorMsg = "Mail verschickt. User ist noch nicht verifiziert";
    } else if (response.request?.status === 400) {
      errorMsg = "Ungültige Zugangsdaten! Versuche es nochmals.";
    } else {
      errorMsg = "Serverproblem. Melde dich beim ksb-it administrator";
    }
  } else if (key === "register") {
    if (response.request?.status === 409) {
      errorMsg = "Email bereits registriert. Bitte versuche es mit einer anderen.";
    } else if (response.request?.status === 500) {
      errorMsg = "Problem mit dem Passwort, wähle neues Passwort";
    } else if (response.request?.status === 401) {
      errorMsg = "Nicht autorisiert. Überprüfe deine Zugangsdaten.";
    } else if (response.request?.status === 400) {
      errorMsg = "Serverprobleme, versuche es später nochmals.";
    } else {
      errorMsg = "Unbekannter Fehler";
    }
  }

  return {[key]: [errorMsg]};
}

exports.getErrorMessageFromResponse = getErrorMessageFromResponse;

