import { memo } from "react";
import "./loginFields.css";

function LoginFields({ email, onChangeEmail, userPassword, onChangePassword }) {
  return (
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "90%",
        }}>
        <input
          type="email"
          placeholder="Email"
          name="email"
          onChange={onChangeEmail}
          value={email}
          required
          className="input"
          autoComplete="email" // Added autocomplete attribute for email
        />
        <input
          type="password"
          placeholder="Passwort"
          name="userPassword"
          onChange={onChangePassword}
          value={userPassword}
          required
          className="input"
          autoComplete="current-password" // Added autocomplete attribute for password
        />
      </form>
  );
}

export default memo(LoginFields);