import { memo } from "react";
import AccountProfile from "./AccountProfile/AccountProfile";
import PasswordUpdate from "./PasswordProfile/PasswordUpdate";
import UsernameDetail from "./UserName/UsernameDetail";

function ProfileContent() {
  return (
    <div
      style={{
        flexDirection: 'column', // Arranges children vertically
      }}
    >
      <AccountProfile />
      <PasswordUpdate />
      <UsernameDetail />
    </div>
  )
}

export default memo(ProfileContent);
