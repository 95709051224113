import { Box, Typography } from "@mui/material";

// used for App Bar
export const ChatAppTitle = () => {

  return (
    <div>
      <div
        style={{
          width: "110%",
          whiteSpace: "nowrap", // Prevent content from wrapping
        }}
      >
        <Typography variant="h6" color="primary">
          KSB
        </Typography>
      </div>
      <>
        <div
          style={{
            width: "110%",
            whiteSpace: "nowrap", // Prevent content from wrapping
          }}
        >
          <Typography color="primary" variant="h4" fontWeight={"bold"}>
            Chat App
          </Typography>
        </div>
        <div
          style={{
            width: "110%",
            whiteSpace: "nowrap", // Prevent content from wrapping
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              pt: 0,
            }}
          >
            <Typography color="primary" variant="caption">
              von Vittoria Huber
            </Typography>
          </Box>
        </div>
      </>
    </div>
  );
};
