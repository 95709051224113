import { memo } from "react";
import "./feedbackMessage.css";

function FeedbackMessage({ errors, msg }) {
  return (
    <div
      style={{
        width: "90%",
        // Other styles...
      }}
    >
      {errors?.length > 0 && (
        <div>
          {errors.map((error, index) => (
            <div
              key={index}
              className="error_msg visible"
              role="alert"
              style={{ marginBottom: "1em" }} // Adjust the margin as needed
            >
              {error}
            </div>
          ))}
        </div>
      )}

      <br />

      {/* success */}
        <div
          className={`${errors?.length > 0?"error_msg":"success_msg"} ${
            msg.length > 0 ? "visible" : ""
          }`}
          role="alert"
        >
          {msg}
        </div>
    </div>
  );
}

export default memo(FeedbackMessage);
