export const validateForm = (formState) => {
    let validationErrors = {
      emailErr: {},
      passwordErr: {},
      firstNameErr: {},
      lastNameErr: {},
      passwordConfirmationErr: {},
    };
  
    let isValid = true;
  
    // Email Validation
    if (formState.email === "") {
      validationErrors.emailErr.userNameEmpty = "E-Mail eingegeben!";
      isValid = false;
    } else if (!formState.email.includes("@sluz")) {
      validationErrors.emailErr.username = "E-mail muss eine SLUZ-Mailadresse sein";
      isValid = false;
    } else if (formState.email.length >= 40) {
      validationErrors.emailErr.userNameTooLong = "E-Mail zu lange!";
      isValid = false;
    }
  
    // Password Validation
    if (formState.password === "") {
      validationErrors.passwordErr.userPasswordEmpty = "Passwort eingegeben!";
      isValid = false;
    } else if (formState.password.length < 7) {
      validationErrors.passwordErr.userPasswordTooShort = "Längeres Passwort eingeben";
      isValid = false;
    }
  
    // Password Confirmation
    if (formState.password !== formState.passwordConfirmation) {
      validationErrors.passwordConfirmationErr.userPasswordNotConfirmed = "Passwörter nicht identisch!";
      isValid = false;
    }
  
    // First Name Validation
    if (formState.firstName === "") {
      validationErrors.firstNameErr.firstNameEmpty = "Vornamen eingegeben!";
      isValid = false;
    } else if (formState.firstName.length >= 30) {
      validationErrors.firstNameErr.firstNameTooLong = "Vorname zu lange!";
      isValid = false;
    }
  
    // Last Name Validation
    if (formState.lastName === "") {
      validationErrors.lastNameErr.lastNameEmpty = "Nachnamen eingegeben!";
      isValid = false;
    } else if (formState.lastName.length >= 30) {
      validationErrors.lastNameErr.lastNameTooLong = "Nachname zu lange!";
      isValid = false;
    }
  
    return { isValid, validationErrors };
  };
  