import { Typography } from "@mui/material";
export const CenterBox = ({ type }) => {

  if (type === "chatApp") {
    return (
      <div style={{
        flex: 1,
        textAlign: "center", // Center along the x-axis
        whiteSpace: "nowrap", // Prevent content from wrapping
      }}>
        <div>
          <Typography variant="h6" color="primary">
            {/* Write something */}
          </Typography>
        </div>
      </div>
    );
  } else if (type === "profile") {
    return (
      <div style={{
        flex: 1,
        textAlign: "center", // Center along the x-axis
        whiteSpace: "nowrap", // Prevent content from wrapping
      }}>
        <div>
          <Typography variant="h6" color="primary">
            Implementierte Funktionen
          </Typography>
        </div>
      </div>
    );
  }
  else {
    return (
      <div style={{
        flex: 1,
        textAlign: "center", // Center along the x-axis
        whiteSpace: "nowrap", // Prevent content from wrapping
      }}>
        <div>
          <Typography variant="h6" color="primary">
            Seite im Development Zustand
          </Typography>
        </div>
        <div>
          <Typography color="primary" variant="h4" fontWeight={"bold"}>
            Dashboard
          </Typography>
        </div>
      </div>
    );
  }
};
