import { memo, useState } from "react";

import {
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import BoxHeader from "../../../../4_generalHelpers/1_Boxes/BoxHeader";
import { useAppContext } from "../../../../8_contexts/contextLib";
import userService from "../../../../6_services/user.service";
import MyButton from "../../../../4_generalHelpers/2_Buttons/MyButton";

function UsernameDetail() {
  const theme = useTheme();
  const [change, setChange] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const { currentUser, setCurrentUser } = useAppContext();
  // console.log(currentUser)

  const [nickName, setNickName] = useState(
    currentUser.userNickName ? currentUser.userNickName : ""
  );

  const changeNickName = (event) => {
    event.target.value === currentUser.nickName ||
      (currentUser.nickName === undefined && event.target.value === "")
      ? setChange(false)
      : setChange(true);
    setNickName(event.target.value);
  };

  const updateLocalUser = () => {
    var updateUser = currentUser;
    if (nickName !== updateUser.userNickName) {
      updateUser.userNickName = nickName;
    }
    // console.log(updateUser);
    setCurrentUser(updateUser);
    localStorage.setItem("user", JSON.stringify(updateUser));
  };

  const updateHandler = (event) => {
    event.preventDefault();
    // alert("function not implemented yet");
    setLocalLoading(true);
    userService
      .updateUser("nickName", { nickName: nickName }, currentUser.id)
      .then(updateLocalUser())
      .then(setLocalLoading(false))
      .then(setChange(false));
    // console.log(currentUser);
  };

  const clearHandler = (event) => {
    event.preventDefault();
    setNickName(currentUser.nickName ? currentUser.nickName : "");
    setChange(false);
  };

  return (


    <>
      <BoxHeader title={`Username Einstellen`} />
      <div style={{ textAlign: "center", marginTop: "1rem" }}>

        <div style={{ marginTop: "1rem" }}>
          <TextField
            fullWidth
            label="Username"
            name="name"
            onChange={changeNickName}
            required
            value={nickName}
            variant="outlined"
          />
        </div>
        <div style={{
          display: "flex", gap: 8, justifyContent: "flex-start", marginTop: "1rem", marginBottom: "1rem", visibility: !change ? 'hidden' : 'visible',
        }}>
          <MyButton
            disabled={!change}
            loading={localLoading}
            job={updateHandler}
            text={"speichern"}
            color={theme.palette.levels.green}
          />
          <MyButton
            disabled={!change}
            loading={localLoading}
            job={clearHandler}
            text={"Verwerfen"}
            color={theme.palette.levels.red}
          />
        </div>
      </div>
    </>
  );
}
export default memo(UsernameDetail);   
