import axios from "axios";
import { SERVER_URL } from "../2_deployment/constants";

const API_REGISTERURL = SERVER_URL + "authentication/sign-up";
const API_VERIFICATIONURL = SERVER_URL + "authentication/verify";

class AuthService {
  async verifyUser(id, code) {
    try {
      const response = await axios.post(API_VERIFICATIONURL, {
        id: id,
        token: code,
      });
      //log the user in directly
      // console.log(response)
      return response.data;
      //can be string or user
      // === "User ist bereits verifziert"
      //   ? response.data
      //   : response.data;
    } catch (error) {
      console.log(error.response);
      // console.log("we came here")
      return undefined;
    }
  }

  async login(username, password) {
    try {
      const response = await axios.post(
        SERVER_URL + "login",
        {
          email: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Add other headers as needed, e.g., Authorization
          },
        }
      );
      // console.log(response);
      if (response.data.token) {
        await localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response;
    } catch (error) {
      // console.log(error.response);
      // console.log("we return this");
      return error;
    }
  }

  async register(registerUser) {
    // console.log(registerUser);
    try {
      // console.log(registerUser);
      const response = await axios.post(API_REGISTERURL, registerUser);
      // console.log(response);
      return response.data;
    } catch (error) {
      // console.log(error.message)
      // console.log(error.request.responseText)
      // console.log(error.request.status)
      return error.request;
    }
  }

  //activstion by userservice

  clearStorage() {
    // console.log("we call the clear function")
    localStorage.removeItem("user");
    localStorage.removeItem("_expiredTime");
    localStorage.removeItem("_rerenderTime");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  editCurrentUser(editedUser) {
    localStorage.setItem("user", JSON.stringify(editedUser));
  }
}

const authServiceInstance = new AuthService();
export default authServiceInstance;
