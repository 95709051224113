import React from "react";

const LoginBox = ({ children }) => (
  <div
    style={{
      // backgroundColor: "red",
      flex: 0.5,
      display: "flex",
      padding: "0.5",
      rowGap: 4,
      maxWidth: "900px",
      height: "100%",
    }}
  >
    {children}
  </div>
);

export default LoginBox;
