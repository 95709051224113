import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorkerRegistration from "./3_authentication/serviceWorkerRegistration";
import reportWebVitals from "./3_authentication/reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename={"/"}>
    <App />
  </BrowserRouter>
);

serviceWorkerRegistration.register();
reportWebVitals();
