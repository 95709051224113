import { Box, Button, CircularProgress, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { memo } from "react";
import { useTheme } from "@mui/styles";
import "./verificationResponse.css";

function VerificationResponse({ checking, validUrl, msg }) {
  const theme = useTheme();
  // useEffect(() => {
  //   console.log(checking);
  // }, [checking]);
  // console.log(msg)

  return (
    <Box
      sx={{ textAlign: "center", alignItems: "center", alignContent: "center" }}
    >
      <img
        alt="404 | husibuech"
        // src="./static/images/undraw_page_not_found_su7k.svg"
        src="/logo195.png"
        style={{
          marginTop: 50,
          display: "inline-block",
          maxWidth: "100%",
          width: 300,
        }}
      />
      {checking ? (
        <div>
          <CircularProgress
            style={{
              color: theme.palette.levels.green,
              marginTop: "1.5rem",
              marginLeft: "0.5rem",
            }}
            size={"3.25rem"}
          />
        </div>
      ) : validUrl ? (
        <>
          <Typography
            marginTop={3}
            marginBottom={3}
            align="center"
            color={theme.palette.levels.green}
            variant="h1"
          >
            Link erfolgreich verifiziert!
          </Typography>
          <Button
            href="/"
            // href="/husibuech/"
            component="a"
            startIcon={<ArrowBackIcon fontSize="small" />}
            sx={{ mt: 3, backgroundColor: theme.palette.levels.green }}
            variant="contained"
          >
            Los geht's!
          </Button>
          {msg.length > 0 && (
            <div
              style={{
                marginTop: "1em",
                width: "100%",
                textAlign: "center",
              }}
              className="success_msg"
            >
              {msg}
            </div>
          )}
        </>
      ) : (
        <>
          <Typography
            marginTop={3}
            marginBottom={3}
            align="center"
            color="primary"
            variant="h1"
          >
            Unzulässiger Link!
          </Typography>
          <Button
            href="/"
            // href="/husibuech/"
            component="a"
            startIcon={<ArrowBackIcon fontSize="small" />}
            sx={{ mt: 3 }}
            variant="contained"
          >
            Zurück zum Login
          </Button>
        </>
      )}
    </Box>
  );
}
export default memo(VerificationResponse);
