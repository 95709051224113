import dayjs from 'dayjs';
import userService from '../../6_services/user.service.js';


export const updateUserOnLogout = async (
  checkTime,
  currentUser,
) => {
  // console.log("we update logout time...", dayjs(checkTime).format('YYYY-MM-DD HH:mm:ss'))
  const lastCheckTime = dayjs(checkTime).format('YYYY-MM-DD HH:mm:ss');
  const updateObject = {
    last_logout: lastCheckTime,
  }
  // console.log(updateObject);
  await userService.updateUser("logout", updateObject, currentUser.id);
}
