import { memo } from "react";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";
import ProfileContent from "./ProfileComponents/ProfileContent";

function ProfilePage() {
  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"profile"} options={["detail"]}>
        <ProfileContent />
      </PageTemplate>
    </div>
  );
}

export default memo(ProfilePage);
