import {
  Visibility,
} from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React, { memo, useState } from "react";

import { useTheme } from "@mui/styles";
import CustomWidthTooltip from "../3_Tooltips/CustomWidthTooltip";
import longText from "../4_Text/LongText";

function BoxHeader({
  title,
  options,
}) {
  const theme = useTheme();
  const [showDetail, setShowDetail] = useState(false);

  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  };

  return (
    <>
      <Typography variant="h4" color={theme.palette.text.blue} noWrap={true}>
        {title}

        {options?.includes("detail") && (
          <IconButton
            aria-label="settings"
            edge="end"
            size="small"
            style={{ marginBottom: "0.1em" }}
            onClick={() => {
              toggleShowDetail();
            }}
          >
            <CustomWidthTooltip title={longText} placement="bottom" arrow>
              <Visibility style={{ color: theme.palette.icon.primary }} />
            </CustomWidthTooltip>
          </IconButton>
        )}

      </Typography>
    </>
  );
}
export default memo(BoxHeader);
