import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../../8_contexts/contextLib";
import { IconButton, Grid, useMediaQuery } from "@mui/material";
import { Logout, Settings } from "@mui/icons-material";
import StyledTooltip from "../../../4_generalHelpers/3_Tooltips/StyledTooltip";
import MyAvatar from "../../../4_generalHelpers/10_Avatar/MyAvatar";
import { useTheme } from "@mui/styles";

function MenuTopBarRight() {
  const { currentUser, isAnAdmin, isAuthenticated, setCurrentPage, logout } =
    useAppContext();

  const navigate = useNavigate();

  const goToSettingsPage = () => {
    setCurrentPage("settings");
    navigate("/einstellungen");
  };

  const goToProfilePage = () => {
    setCurrentPage("profile");
    navigate("/profil");
  };

  const renderAvatar = () => (
    <MyAvatar change={goToProfilePage}tooltipText={"Profil"} ariaLabel={"Profilseite"}/>
  );
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xsss"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
      <Grid
        container
        direction="row"
        spacing={0}
        justifyContent="flex-end"
        alignItems="center"
        display={"flex"}
        overflow={"hidden"}
      >
        <Grid item>{isAuthenticated && currentUser && renderAvatar()}</Grid>
        <Grid item>
          {isAuthenticated && isAnAdmin && !(isSmallScreen || isMobile) && (
            <StyledTooltip title="Einstellungen" placement="bottom-start" arrow>
              <IconButton
                size="medium"
                color="inherit"
                aria-label="Upload"
                aria-haspopup="true"
                onClick={goToSettingsPage}
              >
                <Settings />
              </IconButton>
            </StyledTooltip>
          )}
        </Grid>
        <Grid item style={{paddingRight:"0.3em"}}>
          {isAuthenticated && currentUser && (
            <StyledTooltip title="Logout" placement="bottom-start" arrow>
              <IconButton
                edge="end"
                size="medium"
                color="inherit"
                aria-label="Logout"
                onClick={logout}
              >
                <Logout />
              </IconButton>
            </StyledTooltip>
          )}
        </Grid>
      </Grid>
  );
}

export default memo(MenuTopBarRight);
