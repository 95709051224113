import {
  Avatar,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../../8_contexts/contextLib";
import authService from "../../../../6_services/auth.service";
import imageServiceInstance from "../../../../6_services/image.service";
import BoxHeader from "../../../../4_generalHelpers/1_Boxes/BoxHeader";
import MyButton from "../../../../4_generalHelpers/2_Buttons/MyButton";

function AccountProfile() {
  const theme = useTheme();
  const { currentUser, setCurrentUser } = useAppContext();
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [roleNames, setRoleNames] = useState("");

  // Create a reference for the file input
  const fileInputRef = useRef(null);

  // Image selection handler
  const imageSelectedHandler = (e) => {
    let file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      let imageData = new FormData();
      imageData.append("image", file);

      setImageData(imageData);
      setImagePreview(URL.createObjectURL(file)); // Only create URL if a file is selected
      setFile(file);
    } else {
      // Handle case where no file is selected (cancelled)
      setImagePreview(null);
      setFile(null);
    }
  };

  // Trigger file upload
  const fileUploadHandler = () => {
    setLocalLoading(true);
    var editedUser = currentUser;
    try {
      imageServiceInstance
        .upload(currentUser.id, file)
        .then(
          (response) =>
            (editedUser.avatar = response) +
            authService.editCurrentUser(editedUser) +
            console.log("we trigger this...5") +
            setCurrentUser(editedUser)
        )
        .then(() => fileClearHandler())
        .then(() => setLocalLoading(false));
    } catch (err) {
      console.error(err);
      setLocalLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      var out = [];
      currentUser.roles.forEach((role) => out.push(role));
      setRoleNames(out.join(", "));
    }
  }, [currentUser]);

  // File clear handler
  const fileClearHandler = () => {
    try {
      setFile(null);
      setImagePreview(null);
      setImageData(null);
    } catch {
      setLocalLoading(false);
    }
  };

  const getInitials = (firstName, lastName) => {
    if (firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else if (firstName) {
      return firstName.charAt(0);
    } else if (lastName) {
      return lastName.charAt(0);
    } else {
      return "?";
    }
  };

  // Handle avatar click to trigger file input
  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click manually
    }
  };

  // Handle touchstart and click to trigger the file input
  const handleAvatarTouchStart = (e) => {
    // Prevent the default behavior of touch events to avoid interference
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click manually
    }
  };

  return (
    <>
      <BoxHeader title={`Aktueller Avatar`} options={["detail"]} />
      <div style={{ textAlign: "center", marginTop: "1rem", width: 150 }}>
        <input
          ref={fileInputRef} // Attach the ref to the file input
          id="fileInput"
          type="file"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={imageSelectedHandler}
        />
        <Avatar
          src={imagePreview || (currentUser.avatar && `data:image/jpeg;base64,${currentUser.avatar}`)}
          sx={{
            width: 120,
            height: 120,
            margin: "0 auto",
            cursor: "pointer",
            fontSize: "3rem",
            backgroundColor: theme.palette.primary.main,
          }}
          onClick={handleAvatarClick} // Trigger file input click when Avatar is clicked
          onTouchStart={handleAvatarTouchStart} // Also trigger file input click on touchstart for mobile
        >
          {!imagePreview && !currentUser.avatar && getInitials(currentUser.firstName, currentUser.lastName)}
        </Avatar>

        <div style={{ marginTop: "1rem" }}>
          <Typography variant="h5" component="div">
            {currentUser.userNickName || `${currentUser.firstName} ${currentUser.lastName}`}
          </Typography>
          <Typography variant="body1" color="textSecondary" style={{ marginTop: "4px" }}>
            Funktion: {roleNames}
          </Typography>
        </div>

        <div style={{
          display: "flex", gap: 8, justifyContent: "flex-start", marginTop: "1rem", marginBottom: "1rem", visibility: !file ? 'hidden' : 'visible',
        }}>
          <MyButton
            loading={localLoading}
            job={fileUploadHandler}
            text={"Speichern"}
            disabled={localLoading || !file}
            color={theme.palette.levels.green}
          />
          <MyButton
            loading={localLoading}
            job={fileClearHandler}
            text={"Verwerfen"}
            disabled={localLoading || !file}
            color={theme.palette.levels.red}
          />
        </div>
      </div>
    </>
  );
}

export default memo(AccountProfile);
