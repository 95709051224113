import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { Face } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useAppContext } from "../../8_contexts/contextLib";
import StyledTooltipAvatar from "../3_Tooltips/StyledTooltipAvatar";

function MyAvatar({ change, tooltipText, ariaLabel, isToDo }) {
    const { currentUser, currentPage } =
        useAppContext();

    return (
        <StyledTooltipAvatar title={tooltipText} placement="bottom" arrow>
            <IconButton
                size="medium"
                color={currentPage === "profile" ? "inherit" : "primaryLight"}
                aria-label={ariaLabel}
                onClick={change}
            >
                {currentUser?.avatar ? (
                    <Avatar
                        // sx={{ mr: 1 }}
                        sx={{ width: isToDo?56:40, height: isToDo?56:40 }}
                        src={`data:image/jpeg;base64,${currentUser.avatar}`}
                    >
                        <Face fontSize="small"
                        />
                    </Avatar>
                ) : (
                    <Face />
                )}
            </IconButton>
        </StyledTooltipAvatar>
    )

}

export default memo(MyAvatar);
