import { memo } from "react";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";
import ChatAppContent from "./ChatAppContent";

function ChatApp() {
  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"chatApp"}>
        <ChatAppContent />
      </PageTemplate>
    </div>
  );
}

export default memo(ChatApp);
