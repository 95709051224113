import { memo } from "react";
import { Typography } from "@mui/material";

function ProjectLinkBox() {

  return (
    <div
      style={{
        justifyContent:"start",
        alignItems:"start",
        display: {
          xs: "none",
          ss: "block",
          sm: "block",
          md: "block",
          lg: "block",
          xl: "block",
        },
      }}
    >
      <Typography color="primary" align="center">
        <a href="./static/documents/PlanBar_2022_paper.pdf" target="_blank">
          Mehr über IT-Projekte
        </a>
      </Typography>
      <br/>
      <Typography color="primary" align="center">
        <a href="./static/documents/ER Model KSB-IT.pdf" target="_blank">
          Basic ER Model
        </a>
      </Typography>
    </div>
  );
}

export default memo(ProjectLinkBox);
