import React, { memo, useEffect, useState } from "react";
import MenuTopBarRight from "./MenuTopBarRight";
import MenuTopBarTitle from "./MenuTopBarTitle";
import { useAppContext } from "../../../8_contexts/contextLib";
import MenuTopBarUnauthorized from "./MenuTopBarUnauthorized";
import "./menuTopBarContent.css"; // Ensure the styles are applied

function MenuTopBarContent() {
  const { currentUser, isAuthenticated } = useAppContext();
  const [flexValues, setFlexValues] = useState({
    left: "33%",
    center: "33%",
    right: "33%",
  });

  const [currentScreenWidth, setCurrentScreenWidth] = useState(window.innerWidth);

  // Update flex values based on the screen width
  const updateFlexValue = () => {
    const screenWidth = window.innerWidth;
    setCurrentScreenWidth(screenWidth);

    let flex = "33%"; // Default for larger screens
    if (screenWidth <= 800) {
      flex = "50%"; // For smaller screens
    }

    setFlexValues({
      left: flex,
      center: flex,
      right: flex,
    });
  };

  // Set up resize listener and cleanup
  useEffect(() => {
    updateFlexValue(); // Initial check
    window.addEventListener("resize", updateFlexValue);

    return () => {
      window.removeEventListener("resize", updateFlexValue);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "4em",
        overflow: "hidden", // This is the parent container
      }}
    >
      {isAuthenticated && currentUser ? (
        <>
          {/* LEFT item */}
          <div
            role="left-item"
            style={{
              flex: `0 0 ${flexValues.left}`,
              justifyContent: "flex-start",
              display: currentScreenWidth < 800 ? "none" : "flex",
            }}
          >
            IT Projekte von Lernenden
          </div>

          {/* CENTER item */}
          <div
            role="center-item"
            style={{
              flex: `0 0 ${flexValues.center}`,
              justifyContent: "center",
              display: flexValues.center === "50%" ? "" : "flex",
              overflow: "hidden",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
            }}
          >
            <MenuTopBarTitle flexValue={flexValues.left} />
          </div>

          {/* RIGHT item */}
          <div
            role="right-item"
            style={{
              flex: `0 0 ${flexValues.right}`,
              justifyContent: "flex-end",
              alignItems: "center",
              overflowY: "scroll", // Allow vertical scrolling
              WebkitOverflowScrolling: "touch", // For smooth scrolling
              height: "100%", // Ensure it has a height to show overflow
              maxHeight: "100%", // Prevent overflow issues
              paddingRight: "15px", // To prevent scrollbar clipping
            }}
            className="scrollable-container"
          >
            <MenuTopBarRight />
          </div>
        </>
      ) : (
        <>
          {/* CENTER item only */}
          <div
            role="center-item"
            style={{
              flex: flexValues.center,
              justifyContent: "center",
              alignItems: "center",
              display: flexValues.center === "50%" ? "" : "flex",
              overflow: "hidden",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              // backgroundColor: "red"
            }}
          >
            <MenuTopBarUnauthorized />
          </div>
        </>
      )}
    </div>
  );
}

export default memo(MenuTopBarContent);
