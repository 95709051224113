import { memo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAppContext } from "../../8_contexts/contextLib";
import MobileStartPage from "../2_StartPage/MobileStartPage";
import PrivateRoute from "./0_Routing/PrivateRoute";
import StartPage from "../2_StartPage/StartPage";
import EmailVerify from "../2_StartPage/EmailVerify/EmailVerify";
import ProfilePage from "../3_ProfilePage/ProfilePage";
import Dashboard from "../4_DashboardPage/Dashboard";
import NotFound from "../1_GeneralPages/NotFound";
import Husibuech from "../5_Husibuech/Husibuech";
import ChatApp from "../6_ChatApp/ChatApp";

// import Profile from "../3ProfilePage/Profile";

function AppRoutes() {
  const { isMobile } = useAppContext();

  return (
    <Routes>
      {/* Login */}
      <Route path={"/login"} element={isMobile ? <MobileStartPage /> : <StartPage />} />

      <Route path={"api/user/verify/:id/:code"} element={<EmailVerify />} />

      {/* Dashboard */}
      <Route path={"/"} element={<PrivateRoute />}>
        <Route path={"/"} element={<Dashboard />} />
      </Route>
      <Route path={""} element={<PrivateRoute />}>
        <Route path={""} element={<Navigate replace to="/" />} />
      </Route>
      <Route path={"/dashboard"} element={<PrivateRoute />}>
        <Route path={"/dashboard"} element={<Navigate replace to="/" />} />
      </Route>
      <Route path={"/profil"} element={<PrivateRoute />}>
        <Route path={"/profil"} element={<ProfilePage/>} />
      </Route>
      <Route path={"/chatApp"} element={<PrivateRoute />}>
        <Route path={"/chatApp"} element={<ChatApp />} />
      </Route>
      <Route path={"/husibuech"} element={<PrivateRoute />}>
        <Route path={"/husibuech"} element={<Husibuech />} />
      </Route>
      {/* Everything else */}
      <Route path="*" element={<NotFound />} />


      {/* Settings (only Admins) */}
      {/* <Route path={"/einstellungen"} element={<PrivateRoute />}>
        {isAuthenticated && isAnAdmin ? (
          <Route path={"/einstellungen"} element={<AdminPage />} />
        ) : (
          <Route
            path={"/einstellungen"}
            element={<Navigate replace to="*" />}
          />
        )}
      </Route> */}
    </Routes>
  );
}
export default memo(AppRoutes);
