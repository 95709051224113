import { memo } from "react";
import DashboardContent from "./DashboardContent";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";
// import { useAppContext } from "../../8_contexts/contextLib";
// import DashboardContentMobile from "./DashboardHelpers/DashboardContentMobile";

function Dashboard() {
  // const {isMobile} = useAppContext()
  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"dashboard"}>
        <DashboardContent />
      </PageTemplate>
    </div>
  );
}

export default memo(Dashboard);
