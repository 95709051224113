import { memo } from "react";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";
import HusibuechContent from "./HusibuechContent";

function Husibuech() {
  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"husibuech"}>
        <HusibuechContent/>
      </PageTemplate>
    </div>
  );
}

export default memo(Husibuech);
