import { memo } from "react";
import styles from "./myButton.css";
import { Button, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/styles";

function MyButton({ loading, job, text, disabled, color }) {
  const theme = useTheme();

  const handleClick = (event) => {
    // Pass the event to the job function if needed
    job(event);
  };
  
  return (

    <div>
      {!loading ? (
        <Button
          disabled={disabled}
          variant="contained"
          onClick={handleClick} // pass the event to the job function
          className={`${styles.white_btn}`}
          style={{
            borderRadius: "45px",
            marginBottom: "1em",
            width: "10em",
            backgroundColor: disabled ? theme.palette.primary.light : color || "", //while there are errors
          }}
        >
          {text}
        </Button>) : (
        <CircularProgress
          style={{
            color: theme.palette.primary.main,
          }}
          size={"1.8rem"}
        />
      )}
    </div>
  );
}

export default memo(MyButton);
