async function combineErrorLists(errors) {
  let combinedErrors = [];
  Object.keys(errors).forEach((key) => {
    if (Array.isArray(errors[key])) {
      combinedErrors = combinedErrors.concat(errors[key]);
    }
  });
  return combinedErrors;
}
exports.combineErrorLists = combineErrorLists;
