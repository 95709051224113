import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../../8_contexts/contextLib";
import { Grid, Typography, useMediaQuery } from "@mui/material";

// import StarsCompleted from "./StarsCompleted";
import moment from "moment";
import { useTheme } from "@mui/styles";

function MenuTopBarTitle({ flexValue }) {
  // console.log(flexValue);
  const { setCurrentPage, setReferenceDate } = useAppContext();

  const navigate = useNavigate();

  const goToTodaysDashboard = () => {
    setCurrentPage("dashboard");
    navigate("/");
  };

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xsss"));

  return (
    <div className="nav-center">
      <Grid
        container
        direction="column"
        // spacing={0}
        justifyContent="center"
        alignItems={flexValue === "50%" ? "flex-start" : "center"}
        display={"flex"}
        style={{ 
          paddingLeft: isMobile?"0.5em":"",
          // backgroundColor: "red", 
        }}
      >
        {/* Navigation Icons */}
        <Grid item paddingTop="0.4em">
          <Typography
            variant="h3"
            onClick={goToTodaysDashboard}
            style={{ cursor: "pointer" }}
          >
            ksb-it
          </Typography>
        </Grid>
        <Grid
          item
          sx={
            {
              // background: "red",
              // visibility: isAuthenticated && currentUser?"visible":"hidden"
            }
          }
        >
          {/* <StarsCompleted flexValue={flexValue} /> */}
        </Grid>
      </Grid>
    </div>
  );
}
export default memo(MenuTopBarTitle);
