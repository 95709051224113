import { Typography } from "@mui/material";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

function ChatAppContent() {
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Functions to handle navigation on click
  const handleClickChatApp = () => {
    navigate("/chatApp"); // Navigate to the "app1" page
  };

  const handleClickHusibuech = () => {
    navigate("/husibuech"); // Navigate to the "app2" page
  };

  return (
    <div
      style={{
        display: "flex",         // Use flexbox for layout
        flexDirection: "column", // Stack items vertically (from top to bottom)
        alignItems: "center",    // Center the items horizontally
        justifyContent: "center", // Center the items vertically
        height: "20rem",         // Ensure the container has a fixed height
        gap: "3rem",             // Add gap between the items
      }}
    >
      <div>
        <Typography color="primary" variant="h4" fontWeight={"bold"}>
          This is your development space - enjoy!
        </Typography>
      </div>
    </div >
  );
}

export default memo(ChatAppContent);
