import React, { memo } from "react";
import { School } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

function MenuTopBarUnauthorized() {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",  // Stack the items vertically
        justifyContent: "center", // Center content vertically
        alignItems: "center",     // Center content horizontally
        height: "100%",           // Ensure it takes full height to center
      }}
    >
      {/* Title */}
      <Typography variant="h3" style={{ textAlign: "center" }}>
        ksb-it
      </Typography>

      {/* IconButton below the title */}
      <IconButton sx={{ mt: -1.5 }} disabled={true}>
        <School sx={{ color: theme.palette.background.paper, marginLeft: "-0.4em" }} />
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.background.paper,
            fontSize: "0.8em",
            mr: 0.2,
            mt: -0.2,
          }}
        >
          &nbsp;Dein IT-Projekt
        </Typography>
      </IconButton>
    </div>
  );
}

export default memo(MenuTopBarUnauthorized);
