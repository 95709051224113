import authServiceInstance from "../../../../6_services/auth.service";
const {
  getErrorMessageFromResponse,
} = require("../../../../4_generalHelpers/_ErrorHandling/getErrorMessageFromResponse");

export const handleRegister = (formState, setError, setVerificationCodeStore, setMsg) => {
  const newUser = {
    email: formState.email,
    password: formState.password,
    firstName: formState.firstName,
    lastName: formState.lastName,
  };

  setMsg("");
  
  authServiceInstance.register(newUser)
    .then(async (response) => {
      if (response.status >= 400) {
        // Get error message from the utility function
        const errorData = await getErrorMessageFromResponse("register", response);
        setError(errorData["register"]);
        setVerificationCodeStore("");
      } else {
        setMsg("Nachricht an die Mailaddresse geschickt");
        setVerificationCodeStore(response.verificationCode);
      }
    })
    .catch((err) => {
      console.error(err);
      setError(["Server Probleme"]);
      setVerificationCodeStore("");
    });
};
