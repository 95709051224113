import { Typography } from "@mui/material";
import { useAppContext } from "../../../8_contexts/contextLib";
import moment from "moment";
import "moment/locale/de";
import { ProfileTitle } from "../../3_ProfilePage/ProfileComponents/ProfileTitle";
import { ChatAppTitle } from "../../6_ChatApp/ChatAppComponents/ChatAppPageTitle.js/ChatAppTitle";
moment.locale("de");


export const LeftBox = ({ type }) => {
  const { isMobile, isSmallScreen, referenceDate } = useAppContext()

  if (type === "chatApp") {
    return (<div style={{
      flex: 1,
      textAlign: "start", // Center along the x-axis
      whiteSpace: "nowrap", // Prevent content from wrapping
    }}>
      <ChatAppTitle />
    </div>)
  }
  else if (type === "profile") {
    return (<div style={{
      flex: 1,
      textAlign: "start", // Center along the x-axis
      whiteSpace: "nowrap", // Prevent content from wrapping
    }}>
      <ProfileTitle />
    </div>)
  } else {
    return (
      <div style={{
        flex: 1,
        textAlign: "start", // Center along the x-axis
        whiteSpace: "nowrap", // Prevent content from wrapping
      }}>
        <div>
          <Typography variant="h6" color="primary">
            Willkommen
          </Typography>
        </div>
        <div>
          <Typography color="primary" variant="h4" fontWeight={"bold"}>
            {moment(referenceDate).format(isMobile || isSmallScreen ? "DD.MM." : "DD. MMMM")}
          </Typography>
        </div>
      </div>
    )
  }
};
