import React, { memo, useState } from "react";
import { Grid2, Paper } from "@mui/material";
import ReactCardFlip from "react-card-flip";
import SignUpForm from "./SignUpForm/SignUpForm";
import LoginForm from "./Login/LoginForm";

function MobileStartPage() {
  const [seeLogin, setSeeLogin] = useState(true);
  const [seeRegister, setSeeRegister] = useState(false);

  const changeView = () => {
    setSeeLogin(!seeLogin);
    setSeeRegister(!seeRegister);
  };

  return (
    <div style={{
      flex: 1,
      flexDirection: "center",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: 10,
    }}>

      <Paper
        variant="elevation"
        elevation={5}
        sx={{ borderRadius: 10, width: "90%", maxWidth: 940 }}
      >
        <Grid2 item xs={12} ss={12} sm={12} md={12} padding={3}>
          <ReactCardFlip isFlipped={seeLogin} flipDirection="horizontal">
            <SignUpForm flipCard={changeView} />
            <LoginForm flipCard={changeView} />
          </ReactCardFlip>
        </Grid2>
      </Paper>
    </div>
  );
}

export default memo(MobileStartPage);
