import { useEffect, useState, memo } from "react";
import { LeftBox } from "./LeftBox";
import { CenterBox } from "./CenterBox";
import { RightBox } from "./RightBox";
import { useAppContext } from "../../../8_contexts/contextLib";
import "./menuRibbon.css"; // Ensure the styles are applied

function MenuRibbon({ type }) {
  const { appInDarkMode } = useAppContext()

  const [flexValue, setFlexValue] = useState("33%");

  useEffect(() => {
    const updateFlexValue = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 520) {
        setFlexValue("50%");
      } else {
        setFlexValue("33%");
      }
    };

    updateFlexValue(); // Call the update function on mount

    window.addEventListener("resize", updateFlexValue); // Event listener for window resize

    return () => {
      window.removeEventListener("resize", updateFlexValue); // Clean up the event listener on component unmount
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "6em",
        marginLeft: "1rem",
        marginRight: "1rem",
        overflowX: "hidden", // Hide horizontal overflow
        backgroundColor: appInDarkMode ? "" : "#ffffff",
      }}
    >
      {/* LEFT item */}
      <div
        style={{
          flex: `0 0 ${flexValue}`,
          justifyContent: "center",
          // backgroundColor: "red",
        }}
      >
        <LeftBox type={type} />
      </div>

      {/* CENTER item */}
      <div
        role="center-item"
        style={{
          flex: `0 0 ${flexValue}`,
          justifyContent: "center",
          display: "flex-end",
          overflowY: "scroll", // Allow vertical scrolling
          WebkitOverflowScrolling: "touch", // For smooth scrolling
          // paddingRight: "15px", // To prevent scrollbar clipping
          // backgroundColor: "orange",
        }}
        className="scrollable-container"

      >
        <CenterBox type={type} />
      </div>

      {/* RIGHT item */}
      <div
        role="right-item"
        style={{
          flex: `0 0 ${flexValue}`,
          justifyContent: "center",
          display: "flex",
          overflow: "auto",
          overflowY: "scroll", // Allow vertical scrolling
          WebkitOverflowScrolling: "touch", // For smooth scrolling
          // paddingRight: "15px", // To prevent scrollbar clipping
          // marginInline: "-17px", // Adjust this value to hide the x-axis scrollbar
          // backgroundColor: "yellow",
        }}
        className="scrollable-container"
      >
        <RightBox type={type} />
      </div>

      {/* Media query to hide the items on smaller screens */}
      <style>
        {`
                 @media (max-width: 520px) {
                   div[role="right-item"] {
                     display:"none";
       
                   },
                   div[role="center-item"] {
                     display: "flex";
                   },
       
                 }
               `}
      </style>
    </div>

  );
}
export default memo(MenuRibbon);
