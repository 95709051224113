import { memo } from "react";
import LoginBox from "../../../4_generalHelpers/1_Boxes/LoginBox";
import LoginCard from "./LoginComponents/MainBox/LoginCard";
import LeftInformation from "../LeftInformation/LeftInformation";

function LoginForm({ flipCard, seeLogin }) {
  return (
    <LoginBox>
      <LeftInformation flipCard={flipCard} loginPage={seeLogin} />
      <LoginCard />
    </LoginBox>
  );
}

export default memo(LoginForm);
