import { memo } from "react";
function UserQuestion({ text }) {

  return (
    <div
      style={{
        width: "90%",
        display: {
          xs: "none",
          ss: "block",
          sm: "block",
          md: "block",
          lg: "block",
          xl: "block",
        },
      }}

    >
      <h1
        style={{ color: "black", fontSize: "24px", textAlign: "center" }}
      >
        {text}
      </h1>
    </div>
  );
}

export default memo(UserQuestion);
