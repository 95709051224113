import { Typography } from "@mui/material";
import MenuRibbon from "./Helpers/MenuRibbon";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../8_contexts/contextLib";
import LoadingDashboardContent from "../../4_generalHelpers/Loading/LoadingDashboardContent";
import LoggingOutDashboard from "../../4_generalHelpers/Loading/LoggingOutDashboard";

export const PageTemplate = ({ type, children }) => {
  const theme = useTheme();
  const { loading, loggingOut } = useAppContext()

  return loading ? (
    <LoadingDashboardContent />
  ) : loggingOut ? (
    <LoggingOutDashboard />
  ) :
    <div style={{
      display: "flex",
      flexDirection: "column",
      marginLeft: "1em",
      marginRight: "1em"
    }}>
      {/* MenuRibbon */}
      <div
        style={{
          backgroundColor: theme.palette.background.paper,
          marginTop: "4em",
          height: "6em",
          zIndex: 1, //makes the element float above the elements below (used when scrolling)
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <MenuRibbon type={type} />
      </div>

      {/* Spacer to push down remaining content */}
      <div style={{
        height: "6.5em",
      }} />
      <div
        style={{
          flex: "1",
          // backgroundColor: "pink",
        }}
      >
        {children}
      </div>

      <div
        style={{
          flex: "1",
          marginTop: "2em",
          marginBottom: "2em",
        }}
      >
        <Typography variant="h7" color="textSecondary">
          powerd by: ksb-it
        </Typography>
      </div>
    </div>






};
